<script setup lang="ts">
import { useCollapse } from "~/layers/tairo-layout-collapse/composables/collapse"
import { ModalsContainer } from "vue-final-modal"
import { getCountries } from "~/layers/master/services/useCountry"
import { useCountry } from "~/stores/country"
import { useAuthStore } from "~/auth/stores/auth"
import {
  getCompanySettings,
  getUserCompanies,
} from "~/layers/company/services/useEntity"

const { isOpen, isMobileOpen } = useCollapse()
const store = useCountry()
const auth = useAuthStore()
const company = useCookie("company")
const { data } = useAuth()
await callOnce(async () => {
  if (company.value === undefined) {
    company.value = data.value?.entityId
  }
  const r = await getCountries()
  if (r?.success) {
    store.countries = r?.data
    store.indonesia = r?.data?.find((c: any) => c.name === "Indonesia")?.id
  }
})

onBeforeMount(async () => {
  if (auth.companySettings.length < 1) {
    await getCompanySettings(company.value).then((res) => {
      if (res.success) {
        auth.companySettings = res.data
      }
    })
  }

  if (auth.companies.length < 1) {
    await getUserCompanies().then((res) => {
      if (res.success) {
        auth.companies = res.data
      }
    })
  }
})
</script>

<template>
  <div>
    <TairoCollapseLayout>
      <TairoCollapseNavigation>
        <template #header>
          <NuxtLink to="/" class="flex justify-center items-center py-3">
            <Logo v-if="isOpen || isMobileOpen" height-class="h-14" />
            <LogoSquare v-else height-class="h-14" />
          </NuxtLink>
        </template>
        <template #footer>
          <div v-if="isOpen" class="flex justify-center items-center gap-6">
            <NuxtLink
              to="https://play.google.com/store/apps/details?id=id.co.inatax.app.appinatax&pcampaignid=web_share"
              external
              target="_blank"
              class="flex justify-center items-center py-3 text-xs font-bold"
            >
              <NuxtImg
                src="/images/google-play.png"
                class="h-6"
                provider="imagekit"
                format="webp"
                quality="80"
              />
            </NuxtLink>
            <NuxtLink
              to="https://apps.apple.com/id/app/monitax/id6477530246"
              external
              target="_blank"
              class="flex justify-center items-center py-3 text-xs font-bold"
            >
              <NuxtImg
                src="/images/app-store.png"
                class="h-6"
                provider="imagekit"
                format="webp"
                quality="80"
              />
            </NuxtLink>
          </div>
          <NuxtLink
            to="/"
            class="flex justify-center items-center py-3 text-xs font-bold"
          >
            DMKI<span v-if="isOpen" class="italic ml-1"
              >(Digital Media Kreasi Indonesia)</span
            >
          </NuxtLink>
        </template>
      </TairoCollapseNavigation>
      <slot />
    </TairoCollapseLayout>
  </div>
  <ClientOnly>
    <ModalsContainer />
  </ClientOnly>
</template>

<style scoped></style>
